<template>
  <registeration-layout :class="{ 'send-email-container': emailSend }">
    <div class="custom-container">
      <div class="login-container">
        <div class="login-header">
          <router-link to="/">
            <img class="logo" src="@/assets/images/logo.svg" alt="" />
          </router-link>
        </div>
        <div class="row" v-if="!emailSend">
          <div class="col-lg-8">
            <h5 class="title">
              {{ $t("OOPS") }}
            </h5>
            <h2 class="main-title">
              {{ $t("LOGIN_ENTER_EMAIL") }}
              <span>
                {{ $t("LOGIN_PASSWORD_RECOVERY") }}
              </span>
            </h2>
            <img
              src="@/assets/images/forogt-password.png"
              class="img-fluid forgot-password-imgage"
              alt=""
            />
          </div>
          <div class="col-lg-4">
            <div class="login-body">
              <div class="forgot-password-container">
                <h6 class="form-title">
                  {{ $t("LOGIN_FORGOT_YOUR_PAASSWORD") }}
                </h6>
                <div>
                  <forgot-password
                    :loading="showLoading"
                    @handleResetPassword="handleResetPassword"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="send-email">
            <img src="@/assets/images/send-icon.svg" alt="" />
            <h5>
              {{ $t("LOGIN_SENT_EMAIL") }}
            </h5>
            <h6>
              {{ $t("LOGIN_TROUBLE") }}
            </h6>
            <div class="text-center">
              <router-link to="/login" class="btn back-link" a-tag="routeLogin">
                <b-icon :icon="$i18n.locale == 'en' ? 'arrow-left' : 'arrow-right'"></b-icon>
                {{ $t("GLOBAL_BACK") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </registeration-layout>
</template>

<script>
import ForgotPassword from "@/components/Registeration/ForgotPassword";
import { postForgotPasswordRequest } from "@/api/register";

export default {
  components: {
    ForgotPassword,
  },
  data() {
    return {
      showLoading: false,
      emailSend: false,
    };
  },
  methods: {
    handleResetPassword(formData) {
      const body = {
        ...formData,
      };
      this.showLoading = true;
      this.ApiService(postForgotPasswordRequest(body))
        .then(() => {
          this.emailSend = true;
        })

        .finally(() => {
          this.showLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
