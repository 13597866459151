<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formForgotPassword">
      <b-form-group>
        <TextField
          v-model="form.email"
          rules="required|email"
          :label="$t('LOGIN_ENTER_YOUR_EMAIL')"
          :name="$t('LOGIN_ENTER_YOUR_EMAIL')"
        />
      </b-form-group>

      <b-form-group>
        <Button
          type="submit"
          color="primary"
          :dBlock="true"
          customClass="w-100 text-uppercase"
          :loading="loading"
          a-tag="buttonForgotPassword"
        >
          {{ $t("GLOBAL_NEXT") }}
        </Button>
      </b-form-group>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { TogglePasswordMixins } from "@/mixins/TogglePasswordMixins";

export default {
  mixins: [TogglePasswordMixins],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: null,
        reseturl: process.env.VUE_APP_ROOT + "choose-password",
      },
    };
  },
  methods: {
    onSubmit() {
      this.$emit("handleResetPassword", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
